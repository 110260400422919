<template>
	<div class="inner">
		<div class="news-banner">
			<img src="../assets/carType-banner.png" width="100%" />
			<div class="news-banner-search over pt20 pb20">
				<el-select v-model="selectValue" filterable
						   remote
						   reserve-keyword placeholder="请输入搜索关键词/配置编号" :remote-method="getSearchKey" @change="handleDetails">
					<el-option v-for="item in searchOptions" :value="item.id" :label="item.name" :key="item.value">
					</el-option>
				</el-select>
				<i class="el-icon-search fr ft30"></i>
			</div>
		</div>
		<div class="container">
			<div class="ft20 pd18 mt50 pt20 el-border-bottom">
				<span>当前位置：</span>
				<span @click="$router.push('/')">首页 </span>>
				<span @click="$router.push('/carTypeIntro')">车型介绍 </span>>
				<span class="red" @click="$router.push('/carType-details')">车型详情 </span>
			</div>
			<div class="pt50 pb50">
				<el-row :gutter="50">
					<el-col :span="12">
						<div class="video-player">
							<video :poster="form.carInfo.mainPic" controls width="100%" :src="form.carInfo.mainVideo" height="570px" style="background-color: #000;"></video>
							<ul class="video-other">
								<!--<li class="hover tr3" @click="handleMoreVideo(form.carInfo.code)">-->
									<!--<img src="../assets/video-more-icon.png"-->
										<!--style="width: 28px;margin: 0 auto .15rem;" />-->
									<!--<div class="ft12 blf tc">更多视频</div>-->
								<!--</li>-->
								<li class="hover tr3" @click="handleFunVideo(form.carInfo.code)">
									<img src="../assets/video-gongneng-icon.png"
										style="width: 28px;margin: 0 auto .15rem;" />
									<div class="ft12 blf tc">功能视频</div>
								</li>
							</ul>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="ft22 l16 mb12">
							{{form.carInfo.name}}
						</div>
						<div class="pt16 pb12 pl30 pr30 details-bg">
							<div class="red ft22 bold" v-if="form.carInfo && form.carInfo.price > 0">¥{{form.carInfo.price}}万</div>
							<div class="red ft22 bold" v-else>暂无报价</div>
							<div class="ft16 bl9 pt12" v-if="userType != '' && userType == 2">库存：{{form.carInfo.stock}}台</div>
							<div class="ft16 bl9 pt12" v-if="userType != '' && userType == 2">颜色：{{form.carInfo.colorCode}}</div>
						</div>
						<div class="bl9 ft16 l18 pd24">
							车型：{{form.carInfo.code}}<br />
							车款：{{form.carInfo.carStyle}}<br />
							排量：{{form.carInfo.displacementCode}}<br />
							功率：{{form.carInfo.power}}
						</div>
						<div class="details-btn">
							<el-row>
								<el-col :span="18">
									<div class="pd24 ft16">
										<!--<div>-->
											<!--<el-button type="primary">-->
												<!--<img src="../assets/collect-icon.png" width="20" />-->
												<!--<span class="ml20">收藏</span>-->
											<!--</el-button>-->
										<!--</div>-->
										<div class="mt20">
											<el-button type="infor" @click="isShowCode=true">
												<img src="../assets/car-buy-icon.png" width="20" />
												<span class="ml20">在线购车</span>
											</el-button>
										</div>
										<div class="mt20" @click="handleJoinCar(form.carInfo.code)">
											<el-button type="infor">
												<img src="../assets/duibi-icon2.png" width="20" />
												<span class="ml20">加入对比</span>
											</el-button>
										</div>
									</div>
								</el-col>
								<el-col :span="6">
									<div class="tc mt50 ft16">
										<img src="../assets/qrcode-img.png" width="115" />
										<div>小程序查看</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</el-col>
				</el-row>
				<div class="introinfo-nav tc ft24 el-border-bottom pb30 mt50 pt50 mb30">
					<el-row>
						<el-col :span="12">
							<div :class="current==0?'red':''" @click="changeNav(0)" class="el-border-right hover tr3">
								详细介绍
							</div>
						</el-col>
						<el-col :span="12">
							<div :class="current==1?'red':''" @click="changeNav(1)" class="hover tr3">参数配置</div>
						</el-col>
					</el-row>
				</div>
				<div>
					<div v-show="current == 0" v-html="form.details.content"></div>
					<div v-show="current == 1">
						<div class="pt20 pb20 details-li">
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">价格：{{form.carInfo.price}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">车款：{{form.carInfo.carStyle}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">品牌：{{form.carInfo.brandCode}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">车型：{{form.carInfo.code}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">产品尺寸：{{form.carBasicInfo.carSize}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">油箱容量：{{form.carBasicInfo.tankCapacity}} Ltrs</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">排量：{{form.carInfo.displacementCode}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">功率：{{form.carInfo.power}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">扭矩：{{form.carBasicInfo.torque}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">发动机形式：{{form.carBasicInfo.engineType}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">燃油类别：{{form.carBasicInfo.fuelCategory}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">变速器：{{form.carBasicInfo.transmission}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">四驱：{{form.carBasicInfo.fourWheel}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">差速：{{form.carBasicInfo.diffSpeed}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">轮毂及尺寸：{{form.carBasicInfo.tireSize}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">刹车系统：{{form.carBasicInfo.brakeSystem}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">悬挂系统：{{form.carBasicInfo.suspensionSystem}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">选装概叙：{{form.carAppearance.wheelSize}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">保险杠及包围：{{form.carAppearance.surround}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">泥挡：{{form.carAppearance.mudguard == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">玻璃：{{form.carAppearance.glass}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">天窗：{{form.carAppearance.skylight}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">刹车灯：{{form.carAppearance.stoplight == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">侧镜功能：{{form.carAppearance.sideMirror}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">门把手：{{form.carAppearance.doorHandle}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">脚踏：{{form.carAppearance.pedal}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">防撞杠：{{form.carAppearance.bumper == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">大灯形式：{{form.carAppearance.xenonHeadlamp}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">转向灯：{{form.carAppearance.corneringLamp == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">前雾灯：{{form.carAppearance.frontFogLamp == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">备胎：{{form.carAppearance.spareTyre}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">汽车中网：{{form.carAppearance.carGrid}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">行李架：{{form.carAppearance.luggageRack == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">日间行车灯：{{form.carAppearance.runLights == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">头灯清洗：{{form.carAppearance.headlampCleaning == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">保护条：{{form.carAppearance.protectionStrip == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">彩条：{{form.carAppearance.colorBar == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">备胎形式：{{form.carAppearance.spareTyreLogo}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">拖钩：{{form.carAppearance.towHook}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">安全性概括：{{form.carSafety.safetyDesc}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">底盘升降：{{form.carAppearance.carTail == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">气囊前座/窗帘/前侧/司机膝盖：{{form.carSafety.gasBag}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">电眼：{{form.carSafety.electricEye}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">防碰撞安全系统：{{form.carSafety.antiCollision == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">KDSS：{{form.carSafety.kdss == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">电调记忆方向盘：{{form.carVipassana.steeringWheel == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">中央手扶箱：{{form.carVipassana.jewelryBox == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">桃木装饰：{{form.carVipassana.decorate}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">真皮桃木方向盘+桃木档杆：{{form.carVipassana.corium}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">油箱盖开启器：{{form.carVipassana.opener == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">座椅材质+座位数：{{form.carVipassana.seatNum}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">前排电座：{{form.carVipassana.frontSeat}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">司机记忆座：{{form.carVipassana.memorySeat == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">司机座腰部承托/垂直调节：{{form.carVipassana.verticalAdjust == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">车内后视镜：{{form.carVipassana.rearviewMirror}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">遮阳板：{{form.carVipassana.sunVisor == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">化妆镜及照明灯：{{form.carVipassana.vanityMirror == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">组合仪表：{{form.carVipassana.ipc}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">前后雨刷：{{form.carEquipment.rearWiper == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">电窗：{{form.carEquipment.electricWindow}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">电吸门：{{form.carEquipment.electricDoor}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">电动侧滑门：{{form.carEquipment.electricAutoDoor}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">中央门锁：{{form.carEquipment.centralLock == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">无钥匙进入+一键式启动：{{form.carEquipment.touchStart == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">后挡风加热：{{form.carEquipment.weifaHotline == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">迎宾灯：{{form.carEquipment.welcomeLamp == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">收音机,AUX,USB,蓝牙,9喇叭：{{form.carEquipment.radioSetting}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">空调形式：{{form.carEquipment.automaticAir}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">雷测（自适应巡航）：{{form.carEquipment.cruiseControl == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">冰箱：{{form.carEquipment.iceBox == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">遥控启动：{{form.carEquipment.remoteStart == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">摄像头：{{form.carEquipment.camera}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">导航：{{form.carEquipment.navSite == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">倒影：{{form.carEquipment.inverted == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">后排娱乐系统：{{form.carEquipment.entertainment}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">低速巡航：{{form.carEquipment.speedCruise == 0 ? '无' : '有'}}</span>
							</div>
							<div class="ft22 l16 pt30">
								<img src="../assets/item-img.png" width="16" />
								<span class="ml14">特色配置：{{form.carEquipment.characteristic}}</span>
							</div>
						</div>

					</div>
					<!--<el-row :gutter="80">-->
						<!--<el-col :span="12" v-for="(item,index) in 2" :key="index">-->
							<!--<div class="video-list">-->
								<!--<div class="video-list-img">-->
									<!--<img src="../assets/car-details-img.png" width="100%">-->
								<!--</div>-->
							<!--</div>-->
						<!--</el-col>-->
					<!--</el-row>-->
					<!--<div class="pt20 pb20 details-li">-->
						<!--<div class="ft22 l16 pt30">-->
							<!--<img src="../assets/item-img.png" width="16" />-->
							<!--<span class="ml14">限量版实心丰田标后尾门限量标</span>-->
						<!--</div>-->
						<!--<div class="ft22 l16 pt30">-->
							<!--<img src="../assets/item-img.png" width="16" />-->
							<!--<span class="ml14">真皮座椅:(主副驾驶双电动座椅主10副8)</span>-->
						<!--</div>-->
						<!--<div class="ft22 l16 pt30">-->
							<!--<img src="../assets/item-img.png" width="16" />-->
							<!--<span class="ml14">8气囊前后包围</span>-->
						<!--</div>-->
						<!--<div class="ft22 l16 pt30">-->
							<!--<img src="../assets/item-img.png" width="16" />-->
							<!--<span class="ml14">真皮座椅:(主副驾驶双电动座椅主10副8)</span>-->
						<!--</div>-->
					<!--</div>-->
				</div>
				<div class="flex-between over pt50 mt30 over el-border-bottom pb20">
					<div class="ft24 fl">推荐车型</div>
					<div class="ft18 hover fr bl9" @click="$router.push('/carTypeIntro')">
						<span>查看更多</span>
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
			</div>
			<div class="mb50 pb30">
				<el-row :gutter="38">
					<el-col :span="8" v-for="(item,index) in recommendList" :key="index">
						<div class="video-list hover tr3" @click="handleRouteDetails(item.id)">
							<div class="video-list-img2 video-list-img">
								<div class="ft14 blf line1 tag blf">编号：{{item.code}}</div>
								<el-image fit="cover" style="width: 100%;height: 100%;" :src="item.mainPic"></el-image>
							</div>
							<div class="l16 ft18 mt20 line2 mb30">{{item.name}}</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<!-- 小程序弹出框 -->
		<el-dialog width="470px" :lock-scroll="false" :visible.sync="isShowCode" center :show-close="false">
			<div class="tc pb30">
				<img src="../assets/footer-wechat-img.png" width="258" />
				<div class="ft30 l16 pt40 bl0 pb40">
					请扫码进入<br>小程序购买
				</div>
				<el-button @click="isShowCode=false" type="primary" round style="width: 360px;height: 58px;border-radius: 100px;font-size: 22px;">返回</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { carDetails, pageCarList, saveVisitCar, updateDruation} from '@/api/index.js'
	export default {
		data() {
			return {
				userType: sessionStorage.getItem('userType') || '',
				visitId: '',
				form: {
					carInfo:{
						id: '',
						code: '', // 车型编码
						name: '',  // 车型名称
						mainPic: '', // 车型主图
						mainVideo: '', // 车型主视频
						category:'', // 车型分类
						brandCode: '', // 品牌
						seriesCode: '', // 系列
						countryCode: '',  // 国别
						colorCode: '', // 颜色
						displacementCode: '',  // 排量
						carStyle: '', // 车款
						remark: '', // 备注
						stock: 0, // 库存
						power: '', // 功率
						price: '', // 价格
						vipPrice: '' // vip价格
					},
					carBasicInfo:{
						id: '',
						infoCode: '',  // 车型配置编码
						price: '', // 价格
						remark: '', // 备注
						keyword: '', // 搜索关键词
						carTail: 0, // 是否有尾翼
						carStyle: '', // 车款
						settingCode: '', // 配置编号
						brandCode: '', // 汽车品牌
						modelCode: '', // 车型编码
						carSize: '', // 产品尺寸
						tankCapacity: '', // 油箱容量
						displacement: '', // 排量
						power: '', // 功率
						torque: '', // 扭矩
						engineType: '', // 发动机形式
						fuelCategory: '', // 燃油类别
						transmission: '', // 变速器
						fourWheel: '', // 四驱
						diffSpeed: '', // 差速
						tireSize: '', // 轮胎尺寸
						brakeSystem: '', // 刹车系统:前/后
						suspensionSystem: '', // 悬挂系统:前/后
					},
					carAppearance:{
						id: '',
						infoCode: '', // 车型配置基础信息编码
						wheelSize: '', // 轮毂及尺寸
						surround: '',  // 保险杠及包围
						mudguard: '0', // 泥挡
						glass: '', // 玻璃
						skylight: '', // 天窗
						stoplight: '0', // 刹车灯
						sideMirror: '', // 侧镜
						doorHandle: '', // 门把手
						pedal: '', // 脚踏
						bumper: '0', // 防撞杠
						xenonHeadlamp: '', // 氙气大灯
						corneringLamp: '0', // 转向灯
						frontFogLamp: '0', // 前雾灯
						spareTyre: '', // 备胎
						carGrid: '', // 汽车中网
						luggageRack: '0', // 行李架
						runLights: '0', // 日间行车灯
						headlampCleaning: '0', // 头灯清洗
						protectionStrip: '0', // 保护条
						colorBar: '0', // 彩条
						spareTyreLogo: '', // 备胎标
						towHook: '',  // 拖钩

						carTail: '0', // 底盘升降
					},
					//******安全性******//
					carSafety:{
						id: '',
						infoCode: '', // 车型配置基础信息编码
						safetyDesc: '', // 安全性概括
						gasBag: '', // 气囊前座/窗帘/前侧/司机膝盖
						electricEye: '', // 电眼
						antiCollision: '0', // 防碰撞安全系统
						kdss: '0', // kdss
					},
					/**  内饰 ***/
					carVipassana:{
						id: '',
						infoCode: '', // 车型配置基础信息编码
						steeringWheel: '0', // 电调记忆方向盘
						jewelryBox: '0', // 中央首饰箱
						decorate: '', // 桃木装饰
						corium: '', // 真皮桃木方向盘+桃木档杆
						opener: '0', // 油箱盖开启器
						seatNum: '', // 座椅材质+座位数
						frontSeat: '', // 前排电座
						memorySeat: '0', // 司机记忆座(前排通风或加热)
						verticalAdjust:'0', // 司机座腰部承托/垂直调节
						rearviewMirror: '', // 车内后视镜(手/自动防炫目)
						sunVisor:'0', // 遮阳板
						vanityMirror: '0', // 化妆镜及照明灯
						ipc: '', // 组合仪表
					},
					/** 电子设备 **/
					carEquipment:{
						id: '',
						infoCode: '', // 车型配置基础信息编码
						rearWiper: '0', // 前后雨刷
						electricWindow: '', // 电窗
						electricDoor: '', //电吸门
						electricAutoDoor: '', // 电动侧滑门
						centralLock: '0', // 中央门锁
						touchStart: '0', // 无钥匙进入+一键式启动
						weifaHotline: '0', // 尾发热线
						welcomeLamp: '0', // 迎宾灯
						radioSetting: '', // 收音机,AUX,USB,蓝牙,9喇叭
						automaticAir: '', // 前后自动空调(无后暖气)
						cruiseControl: '0', // 巡航定速(雷达)
						iceBox: '0', // 冰箱
						remoteStart: '0', // 遥控启动
						camera: '', // 摄像头
						antiTheft: '0', // 防盗系统
						navSite: '0', // 导航
						inverted: '0', // 倒影
						entertainment: '', // 后排娱乐系统
						winch: '0', // 电动绞盘
						speedCruise: '0', // 低速巡航
						characteristic: '', // 特色配置
					},
					details:{},
				},
				queryParams: {
					keyword:'', // 关键词
					pageNum: 1,
					pageSize: 1000
				},
				current: 0,
				selectOptions: [],
				searchOptions: [],
				selectValue: '',
				isShowCode:false,

				recommendList: [],  // 推荐车型
			}
		},
		created(){
			this.getCarDetails(this.$route.query.id)
			this.getSearchList()
			this.getRecommendList()
		},

		methods: {
			//导航切换
			changeNav(index) {
				this.current = index
			},
			getCarDetails(id){
				carDetails(id).then(res => {
					if(res.code == 200){
						if(res.data.carInfo){
							this.form.carInfo = {
								id: res.data.carInfo.id,
								code: res.data.carInfo.code, // 车型编码
								name: res.data.carInfo.name,  // 车型名称
								mainPic: res.data.carInfo.mainPic, // 车型主图
								mainVideo: res.data.carInfo.mainVideo, // 车型主视频
								category:res.data.carInfo.category, // 车型分类
								brandCode: res.data.carInfo.brandCode, // 品牌
								seriesCode: res.data.carInfo.seriesCode, // 系列
								countryCode: res.data.carInfo.countryCode,  // 国别
								colorCode: res.data.carInfo.colorCode, // 颜色
								displacementCode: res.data.carInfo.displacementCode,  // 排量
								carStyle: res.data.carInfo.carStyle, // 车款
								remark: res.data.carInfo.remark, // 备注
								stock: res.data.carInfo.stock, // 库存
								power: res.data.carInfo.power, // 功率
								price: res.data.carInfo.price, // 价格
								vipPrice: res.data.carInfo.vipPrice // vip价格
							}
						}
						if(res.data.carBasicInfo){
							this.form.carBasicInfo = {
								id: res.data.carBasicInfo.id,
								infoCode: res.data.carBasicInfo.infoCode,  // 车型配置编码
								price: res.data.carBasicInfo.price, // 价格
								remark: res.data.carBasicInfo.remark, // 备注
								keyword: res.data.carBasicInfo.keyword, // 搜索关键词
								carTail: res.data.carBasicInfo.carTail, // 是否有尾翼
								carStyle: res.data.carBasicInfo.carStyle, // 车款
								settingCode: res.data.carBasicInfo.settingCode, // 配置编号
								brandCode: res.data.carBasicInfo.brandCode, // 汽车品牌
								modelCode: res.data.carBasicInfo.modelCode, // 车型编码
								carSize: res.data.carBasicInfo.carSize, // 产品尺寸
								tankCapacity: res.data.carBasicInfo.tankCapacity, // 油箱容量
								displacement: res.data.carBasicInfo.displacement, // 排量
								power: res.data.carBasicInfo.power, // 功率
								torque: res.data.carBasicInfo.torque, // 扭矩
								engineType: res.data.carBasicInfo.engineType, // 发动机形式
								fuelCategory: res.data.carBasicInfo.fuelCategory, // 燃油类别
								transmission: res.data.carBasicInfo.transmission, // 变速器
								fourWheel: res.data.carBasicInfo.fourWheel, // 四驱
								diffSpeed: res.data.carBasicInfo.diffSpeed, // 差速
								tireSize: res.data.carBasicInfo.tireSize, // 轮胎尺寸
								brakeSystem: res.data.carBasicInfo.brakeSystem, // 刹车系统:前/后
								suspensionSystem: res.data.carBasicInfo.suspensionSystem, // 悬挂系统:前/后
							}
						}
						if(res.data.carAppearance){
							this.form.carAppearance = res.data.carAppearance
						}
						if(res.data.carSafety){
							this.form.carSafety = res.data.carSafety
						}
						if(res.data.carVipassana){
							this.form.carVipassana = res.data.carVipassana
						}
						if(res.data.carEquipment){
							this.form.carEquipment = res.data.carEquipment
						}
						if(res.data.carDetails){
							this.form.details = res.data.carDetails
						}
					}
				}).then(()=>{
					this.getVisitCarInfo(id)
				})
			},

			getVisitCarInfo(id){
				let data = {
					unionid: sessionStorage.getItem("userSession") || '',
					carCode: id,
					visitType: 2
				}
				saveVisitCar(data).then(res=>{
					if(res.code == 200){
						this.visitId =  res.data
					}
				})
			},

			handleJoinCar(code){
				this.$router.push({
					path: '/carTypeVs',
					query:{
						code: code
					}
				})
			},

			/***  获取搜索列表 *****/
			getSearchList(){
				this.queryParams.keyword = this.selectValue
				pageCarList(this.queryParams).then(res => {
					if (res.code === 200) {
						this.selectOptions = res.data.list;
					}
				})
			},
			/** 获取搜索信息  ***/
			getSearchKey(query){
				if (query !== '') {
					setTimeout(() => {
						this.searchOptions = this.selectOptions.filter(item => {
							return item.name.toLowerCase()
									.indexOf(query.toLowerCase()) > -1;
						});
					}, 200);
				} else {
					this.searchOptions = [];
				}
			},

			/** 跳转车型详情信息 **/
			handleDetails(){
				this.getCarDetails(this.selectValue)
			},
			/** 跳转到更多视频 ***/
			handleMoreVideo(code){
				this.$router.push({
					path: '/carType-video-list',
					query:{
						code: code
					}
				})
			},
			/** 功能视频 **/
			handleFunVideo(code){
				this.$router.push({
					path: '/carType-GNvideo-list',
					query:{
						code: code
					}
				})
			},

			/**  获取汽车推荐信息列表 **/
			getRecommendList(){
				let query = {
					code: '',
					name: '',
					category: '3',
					pageNum: 1,
					pageSize: 3
				}
				pageCarList(query).then(res => {
					if (res.code === 200) {
						this.recommendList = res.data.list;
					}
				})
			},

			handleRouteDetails(id){
				this.getCarDetails(id)
				this.$router.push({
					path: '/carType-details',
					query:{
						id: id
					}
				})
			},

			updateVisitTime(){
				updateDruation(this.visitId).then(res=>{

				})
			}
		},

		destroyed() {
			this.updateVisitTime()
		}
	}
</script>

<style scoped="scoped">
	.news-banner .news-banner-search {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		background-color: rgba(255, 255, 255, 0.8);
		width: 690px;
		font-size: 18px;
	}

	input {
		background: transparent;
		width: 80%;
	}

	.details-btn .el-button {
		width: 150px;
		font-size: 16px;
	}

	.details-btn .el-button img,
	.details-btn .el-button span {
		display: inline-block;
		vertical-align: middle;
		float: left;
		line-height: 1.3;
	}

	.details-bg {
		background-color: #f6f6f6;
	}

	.video-player {
		position: relative;
		overflow: hidden;
		width: 100%;
	}

	.video-player .video-other {
		position: absolute;
		top: 50%;
		right: 30px;
		transform: translateY(-50%);
	}

	.video-player .video-other li {
		width: 90px;
		height: 90px;
		text-align: center;
		margin-bottom: 20px;
		background-color: rgba(0, 0, 0, .8);
		border-radius: 100px;
		box-sizing: border-box;
		padding-top: 20px;
	}

	.details-li img {
		display: inline-block;
		line-height: 1.6;
		vertical-align: baseline;
	}

	.video-list-img {
		width: 100%;
		background-color: #ddd;
		background-position: center;
		background-size: cover;
		border-radius: 10px;
	}

	.video-list-img .tag {
		background: rgba(0, 0, 0, .5);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		padding: 10px 20px;
		border-radius: 8px 0 0 0;
	}
	.video-list-img2{
		width: 100%;
		background-color: #ddd;
		background-position: center;
		background-size: cover;
		border-radius: 10px;
		height:374px ;
		overflow: hidden;
	}
	.news-banner{position: relative;}
	.news-banner .news-banner-search {
		background-color: rgba(255, 255, 255, 0.8);
		width: 690px;
		font-size: 18px;
		position: absolute;
		top: 40%;
		left: 50%;
		z-index: 2;
		transform: translate(-50%, -35%);
	}
	.news-banner .news-banner-search .el-select /deep/ .el-input__suffix {
		display: none;
	}
	.news-banner .news-banner-search .el-icon-search {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;
	}

	.news-banner .news-banner-search .el-select {
		width: 100%;
	}
	.news-banner .news-banner-search .el-select /deep/ .el-input__inner {
		background-color: transparent;
		font-size: 18px;
		border: none;
		font-weight: normal;
		padding: 0 30px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		padding-right: 60px;
	}
	.el-select-dropdown .el-select-dropdown__item {
		font-size: 16px;
		padding: 10px 15px;
		height: auto;
		width: 100%;
	}
	.news-banner .news-banner-search .el-select /deep/ .el-input__inner::-moz-placeholder{color: #666;}
	.news-banner .news-banner-search .el-select /deep/ .el-input__inner::-ms-input-placeholder{color: #666;}
	.news-banner .news-banner-search .el-select /deep/ .el-input__inner::-webkit-input-placeholder{color: #666;}
</style>

